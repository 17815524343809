<template>
  <section class="notifications">
    <span class="coming-soon">Coming soon...</span>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>